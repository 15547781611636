import React, { type PropsWithChildren } from 'react';
import { useUser } from './userProvider';
import { RealTimePnL, RealTimePositions, RealTimePrices } from '@arlequin-finance/af-frontend-sdk';

export const RealTime = (props: PropsWithChildren): JSX.Element => {
  const { subaccountId } = useUser();

  return (
    <>
      {props.children}
      {subaccountId && (
        <>
          <RealTimePnL subAccountId={subaccountId.toString()} />
          <RealTimePrices liteModeEnable={false} />
          <RealTimePositions
            subAccountId={subaccountId.toString()}
            notification={() => { }}
          />
        </>
      )}
    </>
  );
};
