/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useMemo, useState } from 'react';
import { CIQ } from 'chartiq';
import { type UnderlyingId, usePrice, usePnl, usePositions, useCurrentOrders, type UniversalRequestByList, useInstruments, type UnderlyingSource, type SubAccountId } from '@arlequin-finance/af-frontend-sdk';
import { type Account, type AccountPosition } from './account/Account';
import nameValueStore from './nameValueStore';
import { type QuoteFeed } from './quoteFeed';
import { type CIQAccountOrders, type CIQAccountOrder } from './types/order';
import { useChartTheme } from '../Providers/Theme';

interface ChartRealTimeDataProps {
  chartRef: any
  account: Account | null
  quoteFeed: QuoteFeed
  subAccountId: SubAccountId
  underlyingId: UnderlyingId
  underlyingSource: UnderlyingSource
}

export default function ChartRealTimeData(
  props: ChartRealTimeDataProps,
): JSX.Element {
  const { chartRef, underlyingId, underlyingSource, quoteFeed, subAccountId } = props;
  const [theme, setTheme] = useState(localStorage.getItem('theme'));
  const THEME = useChartTheme();

  const uiContext = chartRef?.uiContext;
  const stx = chartRef?.stx;

  const themeHelper = useMemo(
    () => new (CIQ as any).ThemeHelper({ stx }),
    [],
  );

  const { data: price } = usePrice(underlyingId, underlyingSource);
  const { data: pnl } = usePnl(subAccountId ?? '');
  const { data: positions } = usePositions(subAccountId ?? '');
  const { data: allCurrentOrders } = useCurrentOrders(subAccountId ?? '');

  const openPositions = useMemo(
    () =>
      positions?.filter((position) => {
        if (position.status == 'closed') return false;
        if (position.underlying_id != underlyingId) return false;
        if (position.underlying_price_source != underlyingSource) return false;

        return true;
      }) ?? [],
    [positions, underlyingId, underlyingSource],
  );

  const currentOrders = useMemo(
    () =>
      allCurrentOrders?.filter((order) => {
        if (order.instrument_id != underlyingId) return false;
        if (order.instrument_price_source != underlyingSource) return false;
        return true;
      }) ?? [],
    [allCurrentOrders, underlyingId, underlyingSource],
  );

  const products = useMemo(() => {
    const productsList: UniversalRequestByList = [];

    openPositions.forEach((position) => {
      productsList.push({
        id: position.underlying_id,
        source: position.underlying_price_source,
      });
    });

    currentOrders.forEach((order) => {
      productsList.push({
        id: order.instrument_id,
        source: order.instrument_price_source,
      });
    });

    return productsList;
  }, [currentOrders, openPositions]);

  const { data: instruments } = useInstruments(products);

  const sendRealTimePrice = (): void => {
    if (price?.last == null) return;
    if (price?.last_t == null) return;

    if (stx != null) {
      stx.streamParameters.maxTicks = 0;
      stx.streamParameters.maxWait = 0;
    }

    quoteFeed.realTimePriceUpdate(uiContext, {
      price: price.last,
      bid: price.bid,
      ask: price.ask,
      time: price.last_t,
      volume: price.vol ?? undefined,
    });
  };

  const sendRealTimeSpread = (): void => {
    try {
      if (stx == null) throw new Error('stx is null');
      if (price == null) throw new Error('price is null');

      if (price.bid_t == null) return;
      if (price.ask_t == null) return;
      if (price.ask == null) return;
      if (price.bid == null) return;

      const bidName = `bid${underlyingId}_${underlyingSource}`;
      const askName = `ask${underlyingId}_${underlyingSource}`;

      stx.removeSeries(bidName);
      stx.removeSeries(askName);

      const options = {
        shareYAxis: true,
        useChartLegend: false,
        highlightable: false,
        symbolObject: {
          exchange: '',
          underlyingId,
          underlyingSource,
          isBA: true,
        },
      };

      stx.addSeries('Bid', {
        ...options,
        color: '#eb403400',
        symbolObject: {
          ...options.symbolObject,
          symbol: bidName,
          name: 'Bid',
        },
        data: [
          {
            DT: quoteFeed.formatDate(
              new Date(price.bid_t ?? 0),
              quoteFeed.granularity,
            ),
            Value: price?.bid,
          },
        ],
      });

      stx.addSeries('Ask', {
        ...options,
        color: '#1bcc4d00',
        symbolObject: {
          ...options.symbolObject,
          symbol: askName,
          name: 'Ask',
        },
        data: [
          {
            DT: quoteFeed.formatDate(
              new Date(price?.ask_t ?? 0),
              quoteFeed.granularity,
            ),
            Value: price?.ask,
          },
        ],
      });
    } catch (error) {
      console.error('error add series bid ask', error);
    }
  };

  const setChartTheme = (): void => {
    if (THEME == null) return;
    if (stx == null) return;
    if (themeHelper == null) return;

    const currentChartTheme = nameValueStore.get(
      'themes__core-chartCIQ.Themes.prototype.current',
      () => {},
    );

    if (
      (currentChartTheme && currentChartTheme.theme == 'arlequin') ||
      JSON.stringify(currentChartTheme) === '{}'
    ) {
      themeHelper.settings = THEME;
    }

    themeHelper.update();
  };

  useEffect(() => {
    sendRealTimeSpread();
    sendRealTimePrice();
  }, [
    chartRef,
    underlyingId,
    underlyingSource,
    price?.last,
    price?.ask,
    price?.bid,
  ]);

  useEffect(() => {
    const getTheme = (): void => { setTheme(localStorage.getItem('theme') ?? ''); };
    window.addEventListener('themeChanged', getTheme);
    return () => {
      window.removeEventListener('themeChanged', getTheme);
    };
  }, []);

  useEffect(() => {
    if (props.account == null) return;

    if (pnl?.liquidity) {
      props.account.balances.cash = pnl.liquidity;
      props.account.balances.liquidity = pnl.liquidity;
      props.account.balances.profitLoss = pnl.total_pnl;
    }
  }, [pnl, props.account]);

  useEffect(() => {
    if (props.account == null) return;
    if (instruments == null) return;
    if (openPositions.length == 0) {
      stx.tfc.account.positions = {};
      stx.tfc.account.trades = {
        [stx.tfc.stx.chart.symbol]: [],
      };
      return;
    }

    const instrument =
      instruments?.[openPositions[0].underlying_price_source]?.[
        openPositions[0].underlying_id
      ];

    if (instrument == null) return;

    const productSymbol =
      instrument.symbol ?? instrument.shortName ?? instrument.fullName;

    if (productSymbol == null) return;

    const accountTrades: any = {
      [productSymbol]: [],
    };

    const aggregatedPositions = openPositions.reduce<AccountPosition>(
      (accPosition, position) => {
        const directionFactor = position.direction === 'short' ? -1 : 1;
        accountTrades[productSymbol].push({
          quantity:
            position.cumulated_quantity * position.leverage * directionFactor,
          basis: position.avg_buy_price,
          currency: instrument?.currency ?? 'EUR',
          price: 0,
          position,
          time: new Date(position.latest_update_timestamp.replace(/-/g, '/').replace('.000000000', '')).getTime(),
          id: position.position_key,
        });
        return {
          quantity: openPositions?.length,
          basis: accPosition.basis + position.avg_buy_price,
          currency: accPosition.currency,
          price: 0,
          positions: accPosition.positions?.concat(position),
        };
      },
      {
        quantity: 0,
        basis: 0,
        currency: '',
        price: 0,
        positions: [],
      },
    );

    stx.tfc.account.positions = { [productSymbol]: aggregatedPositions };
    stx.tfc.account.trades = accountTrades;

    stx.tfc.updateData();
    stx.tfc.updateValues();
  }, [
    openPositions,
    instruments,
    props.account,
    props.underlyingId,
    props.underlyingSource,
    stx,
  ]);

  useEffect(() => {
    if (props.account == null) return;
    if (instruments == null) return;

    const accountsOrders: CIQAccountOrders = {};

    for (const order of currentOrders) {
      const instrument =
        instruments?.[order.instrument_price_source]?.[order.instrument_id];

      if (instrument == null) continue;

      const productSymbol =
        instrument.symbol ?? instrument.shortName ?? instrument.fullName;

      if (productSymbol == null) continue;

      if (accountsOrders[productSymbol] == null) {
        accountsOrders[productSymbol] = [];
      }

      const limitPrice = {
        limit: order.order_type === 'LMT' ? order.target_price : undefined,
      };
      const stpPrice = {
        stop: order.order_type === 'STP' ? order.target_price : undefined,
      };

      const directionFactor = order.position === 'short' ? -1 : 1;

      accountsOrders[productSymbol].push({
        id: order.order_id,
        action: order.action.toLocaleLowerCase() as CIQAccountOrder['action'],
        quantity: order.quantity * directionFactor,
        currency: instrument?.currency ?? 'EUR',
        tif: order.validity,
        ...limitPrice,
        ...stpPrice,
        currentOrder: order,
      });
    }

    stx.tfc.account.openOrders = accountsOrders;
    stx.tfc.updateData();
    stx.tfc.updateValues();
  }, [
    openPositions,
    instruments,
    props.account,
    currentOrders,
    props.underlyingId,
    props.underlyingSource,
    stx.tfc,
  ]);

  useEffect(() => {
    setChartTheme();
  }, [theme, THEME]);

  useEffect(() => {
    setTimeout(() => { setChartTheme(); }, 100);
    setTimeout(() => { setChartTheme(); }, 500);
    setTimeout(() => { setChartTheme(); }, 1000);
    setTimeout(() => { setChartTheme(); }, 5000);
  }, []);

  return <></>;
}
