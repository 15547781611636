import { z } from 'zod';
import { Account } from '../Account';
import { OrderValiditySchema, SubAccountIdSchema, UserAccountsSchema, userAccountsKey, type OrderType, editOrder } from '@arlequin-finance/af-frontend-sdk';
import { CIQAccountOrderSchema } from '../../types/order';

export const CIQReplaceOrderSchema = z.object({
  id: z.string(),
  type: z.literal('replace'),
  symbol: z.string(),
  action: z.union([z.literal('buy'), z.literal('sell')]),
  limit: z.object({
    old: z.number().optional(),
    new: z.number().optional(),
  }),
  stop: z.object({
    old: z.number().optional(),
    new: z.number().optional(),
  }),
  tif: z.object({
    old: OrderValiditySchema,
    new: OrderValiditySchema,
  }),
  quantity: z.object({
    old: z.number(),
    new: z.number(),
  }),
});

export type CIQReplaceOrder = z.infer<typeof CIQReplaceOrderSchema>;

export function replaceCIQOrder(
  tfc: unknown,
  order: unknown,
  cb: unknown,
  self: Account,
): void {
  try {
    if (typeof cb !== 'function') throw new Error('callback is not a function');

    const ciqOrder = CIQReplaceOrderSchema.safeParse(order);

    if (!ciqOrder.success) throw new Error('invalid order schema');

    if (!tfc || typeof tfc !== 'object') { throw new Error('tfc is not an object'); }

    if (!('modifyingOrder' in tfc) || typeof tfc.modifyingOrder !== 'object') { throw new Error('tfc.modifyingOrder is not an object'); }

    const modifyingOrder = CIQAccountOrderSchema.safeParse(tfc.modifyingOrder);

    if (!modifyingOrder.success) { throw new Error('invalid modifyingOrder schema'); }

    if (!self || !((self as any) instanceof Account)) { throw new Error('self is not an Account'); }

    const queryClient = self.queryClient;

    const subAccountId = SubAccountIdSchema.parse(
      self.subAccountId,
    );

    const userAccounts = UserAccountsSchema.parse(
      queryClient.getQueryData([userAccountsKey]),
    );

    const userAccount = userAccounts?.find(
      (account) => account.id == subAccountId,
    );
    if (userAccount == null) throw new Error('userAccount is null');

    const limit = ciqOrder.data.limit.new;
    const stop = ciqOrder.data.stop.new;

    const target = limit ?? stop;
    if (target == null) throw new Error('target is null');

    const orderType: OrderType = limit == null ? 'STP' : 'LMT';

    editOrder(
      userAccount,
      modifyingOrder.data.currentOrder,
      {
        order_type: orderType,
        target_price: target,
        validity: ciqOrder.data.tif.new,
        quantity: ciqOrder.data.quantity.new,
      },
      'web',
    ).then(cb()).catch((error) => {
      console.error('replaceCIQOrder :', error);
    });
  } catch (error) {
    console.error('replaceCIQOrder :', error);
    alert(error);
  }
}
