import React, { type PropsWithChildren } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Query } from '@arlequin-finance/af-frontend-sdk';

const queryClient = new QueryClient();

export default function TanstackQuery(props: PropsWithChildren): JSX.Element {
  return (
    <QueryClientProvider client={queryClient}>
      <Query queryClient={queryClient} devTools={false}>
        {props.children}
        </Query>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
