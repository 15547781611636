import { PositionSchema, SubAccountIdSchema, UserAccountsSchema, userAccountsKey, sellPosition } from '@arlequin-finance/af-frontend-sdk';
import { Account } from '../Account';
import { z } from 'zod';

export const tradeSchema = z.object({
  quantity: z.number(),
  basis: z.number(),
  currency: z.string(),
  price: z.number(),
  position: PositionSchema,
  time: z.number(),
  id: z.string(),
});

export function closeTrades(
  tfc: unknown,
  trade: unknown,
  cb: unknown,
  self: Account,
): void {
  try {
    if (typeof cb !== 'function') throw new Error('callback is not a function');

    if (!tfc || typeof tfc !== 'object') { throw new Error('tfc is not an object'); }

    if (!self || !((self as any) instanceof Account)) { throw new Error('self is not an Account'); }

    const queryClient = self.queryClient;

    const subAccountId = SubAccountIdSchema.parse(
      self.subAccountId,
    );

    const userAccounts = UserAccountsSchema.parse(
      queryClient.getQueryData([userAccountsKey]),
    );

    const userAccount = userAccounts?.find(
      (account) => account.id == subAccountId,
    );
    if (userAccount == null) throw new Error('userAccount is null');

    const position = tradeSchema.parse(trade).position;

    sellPosition(userAccount, position, self.origin).then(cb()).catch((error) => {
      console.error('cancelCIQOrder :', error);
    });
  } catch (error) {
    console.error('replaceCIQOrder :', error);
    alert(error);
  }
}
