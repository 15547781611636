import { SubAccountIdSchema, UserAccountsSchema, userAccountsKey, InstrumentSchema, instrumentKey, sellPosition } from '@arlequin-finance/af-frontend-sdk';
import { Account } from '../Account';

export function closeAllCIQOrder(
  tfc: unknown,
  cb: unknown,
  self: Account,
): void {
  try {
    if (typeof cb !== 'function') throw new Error('callback is not a function');

    if (!tfc || typeof tfc !== 'object') { throw new Error('tfc is not an object'); }

    if (!self || !((self as any) instanceof Account)) { throw new Error('self is not an Account'); }

    const queryClient = self.queryClient;

    const subAccountId = SubAccountIdSchema.parse(
      self.subAccountId,
    );

    const userAccounts = UserAccountsSchema.parse(
      queryClient.getQueryData([userAccountsKey]),
    );

    const userAccount = userAccounts?.find(
      (account) => account.id == subAccountId,
    );
    if (userAccount == null) throw new Error('userAccount is null');

    const instrument = InstrumentSchema.parse(
      queryClient.getQueryData([
        instrumentKey,
        self.underlyingId,
        self.underlyingSource,
      ]),
    );

    const symbole =
      instrument?.symbol ?? instrument?.shortName ?? instrument?.fullName;

    if (!symbole) throw new Error('symbole is null');

    const positions = self.positions[symbole];

    if (!positions) throw new Error('positions is null');
    if (!positions.positions) throw new Error('positions.positions is null');

    positions.positions.forEach((position) => {
      sellPosition(userAccount, position, self.origin).then(cb()).catch((error) => {
        console.error('cancelCIQOrder :', error);
      });
    });
  } catch (error) {
    console.error('replaceCIQOrder :', error);
    alert(error);
  }
}
