import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import ChartLoader from './Components/ChartLoader';
import { UserProvider } from './Providers/userProvider';
import { logLevel, sdk } from '@arlequin-finance/af-frontend-sdk';
import TanstackQuery from './Providers/query';

import './global.scss';
import Theme from './Providers/Theme';
import { RealTime } from './Providers/RealTime';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

sdk.env = 'PROD';
sdk.logLevel = logLevel.DEBUG;

root.render(
  <React.StrictMode>
    <UserProvider>
      <TanstackQuery>
          <RealTime>
            <Theme>
              <ChartLoader>
                <App />
              </ChartLoader>
            </Theme>
          </RealTime>
      </TanstackQuery>
    </UserProvider>
  </React.StrictMode>,
);
